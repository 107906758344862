.feed-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
}
