video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video-full {
  object-fit: cover;
}

.video-center {
  object-fit: contain;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  object-fit: cover;
  z-index: -1;
}

.player-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.player-video {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* transition: opacity 0.2s; */
  background-color: #100f0f;
  opacity: 0.9;
}

.player-video.underlay {
  opacity: 0.4;
}

.btn-play-pause {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  pointer-events: none;
}

.btn-play-pause.active {
  pointer-events: auto;
}

.btn-play {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: var(--color-mid-gray);
  opacity: 0.7;
  border: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (hover: hover) {
  .btn-play:hover {
    opacity: 0.9;
  }

  .btn-play.underlay:hover {
    opacity: 0.7;
  }
}

.short-player-title {
  position: absolute;
  left: 20px;
  bottom: 53px;
  max-width: calc(100% - 8.5rem);
  z-index: 801;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important;
}

.player-share-btn {
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important;
}

.short-title-text {
  font-weight: 700 !important;
  /* font-size: 1.2rem !important; */
  font-size: 15px !important;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.short-desc-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.short-player-right-bottom {
  position: absolute;
  right: calc(20px - 17.5px);
  bottom: calc(53px - 17.5px); /* 50px + 슬라이더 크기 4px + 여유1px */
  z-index: 801;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important ;
  -webkit-transform: translate3d(0, 0, 0) !important;
}

.short-player-like {
  position: absolute;
  right: calc(-2rem + 20px);
  bottom: 240px; /* 공유하기 bottom 150px + 공유하기 36px + 여백 35px */
  z-index: 801;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important ;
  -webkit-transform: translate3d(0, 0, 0) !important;
}

.short-player-call {
  position: absolute;
  right: 108px; /* Close 버튼 22px + close 좌우 여백 40px */
  top: -0.6rem;
  z-index: 801;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important ;
  -webkit-transform: translate3d(0, 0, 0) !important;
}

.short-player-share {
  position: absolute;
  right: 48px; /* Close 버튼 22px + close 좌우 여백 40px */
  top: -0.6rem;
  z-index: 801;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important ;
  -webkit-transform: translate3d(0, 0, 0) !important;
}

.short-category-title {
  font-weight: 700 !important;
  /* font-size: 1.1rem !important; */
  font-size: 14px !important;
  /* line-height: 2.4rem !important; */
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.short-player-slider {
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: 5px;
  z-index: 801;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d !important ;
  -webkit-transform: translate3d(0, 0, 0) !important;
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: none !important;
}
