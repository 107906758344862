.thd-share-more-body-box {
    width: 100%;
    height: 201px;
    padding-top: 15px;
    background-color: "white";
  }

.thd-share-top-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.thd-share-header {
    padding: 0px 15px 5px 25px;
    display: flex;
    justify-content: space-between;
}

.thd-share-header-title {
    font-family: "Noto Sans KR";
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #010000;
}

.thd-share-row {
    width: 100%;
    padding: 25px;
}

.thd-share-item-small {
    width: 20vw;
    height: 60px;
}