:root {
  /* --pink-color: #ea5c84; */
  --rolling-box-size: 65px;
}

.thd-thd-prod-circle-button-box {
  /* width: var(--footer-left-size); */
  width: 80%;
  height: var(--rolling-box-size);
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.thd-prod-circle-button {
  overflow: hidden;
  /* width: 50px; */
  white-space: nowrap;
  /* position: relative; */
  width: 80%;
}

div.thd-slider-circle {
  /* width: 52px; */
  height: var(--rolling-box-size);
  /* display: flex; */
  flex-direction: column;
  flex-wrap: nowrap;
  user-select: none;
}

.thd-slider-circle div.slideItem {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #111111;
  padding-left: 10px;
  overflow: hidden;
}

img.thd-item-thumb {
  object-fit: contain;
  width: 43px;
  height: 43px;
  border-radius: 6px;
}

.thd-item-desc {
  overflow: hidden;
  margin-left: 10px;
}

.thd-item-title {
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: var(--item-title-size);
  line-height: 20px;
  flex-wrap: nowrap;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.thd-item-price {
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: var(--item-price-size);
  line-height: 23px;
}

.thd-prod-circle-cnt {
  color: #999999;
  font-size: 18px;
  display: flex;
  padding: 1px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.thd-prod-circle-cnt span {
  font-family: "Noto Sans KR";
}

.thd-live-more-body-box {
  width: 100%;
  height: 60vh;
  padding-top: 15px;
  background-color: "white";
}

.thd-prod-scroll {
  overflow-y: scroll;
  height: calc(60vh - 67px);
}

.thd-prod-scroll::-webkit-scrollbar {
  display: none;
}

.thd-prod-list-top-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.thd-prod-list-header {
  padding: 0px 15px 5px 15px;
  display: flex;
  justify-content: space-between;
}

.thd-prod-list-header-title {
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #010000;
}

.thd-prod-list-header span {
  color: #009d76;
  font-family: "Noto Sans KR";
  font-weight: 400;
  margin-left: 7px;
}

.thd-prod-list {
  margin: 0;
  padding: 10px 10px 0px 10px;
  display: flex;
  flex-wrap: wrap;
  /* width: 100%;
  height: 100%; */
}

.thd-prod-item-div {
  display: inline-block;
  width: 50%;
}

.thd-prod-item-box {
  padding: 0px 5px 20px 5px;
  cursor: pointer;
}

.thd-prod-item-info {
  height: 100%;
  width: 100%;
}

.thd-prod-image-box {
  /* padding: 10px; */
  /* flex-basis: 25%; */
  /* display: flex;
  /* justify-content: center;
  align-items: center; */
  /* overflow: hidden;  */
}

.thd-prod-image-box img {
  width: 100%;
  /* height: calc((100vw + 9px) / 2 * (3 / 4)); */
  object-fit: cover;
  border-radius: 7px;
}

.thd-prod-desc-box {
  margin: 10px;
  /* display: flex;
  flex-direction: column;
  flex-basis: 75%;
  padding: 10px;
  justify-content: center; */
}

.thd-prod-desc-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
}

.thd-prod-desc-price {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #010000;
  margin-top: 4px;
}

.thd-prod-desc-price span {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
}

.thd-sold-out-filter {
  display: flex;
  background-color: #cfcfcf58;
  width: 100%;
  height: 100%;
}
